import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'
import { StaticImage } from 'gatsby-plugin-image'

const MainHeaderStyles = styled.header`
  width: 100%;
  height: 100vh;
  padding-top: 5rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  position: relative;
  .main-header {
    width: 85%;
    margin: var(--auto);
    padding-bottom: 2rem;
    h1 {
      color: var(--white);
      font-weight: 900;
      position: relative;
      line-height: 1.25;
      text-align: center;
      text-shadow: 0px 4px 22px #000;
      span {
        .dot {
          color: var(--cyan);
        }
      }
      /* span {
        position: relative;
        .dot {
          color: var(--cyan);
          font-size: 3rem;
          vertical-align: text-bottom;
          position: absolute;
          bottom: 12.5px;
        }
      } */
    }
    .header-arrow {
      display: none;
    }
  }
  .main-header--bkg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      img {
      }
    }
    .desktop-img {
      display: none;
    }
  }

  @media ${media.sm} {
    padding-top: 0;
    align-items: center;
    /* height: 82vh; */
    .main-header {
      width: 100%;
      text-align: center;
      h1 {
        width: 100%;
        margin: 0;
        /* span {
          .dot {
            font-size: 5.5rem;
            bottom: 2px;
          }
        } */
      }
      p {
        margin-top: 0.5rem;
        color: var(--white);
        font-size: 1.25rem;
      }
      .header-arrow {
        display: block;
        position: absolute;
        bottom: 1rem;
        left: 0;
        width: 100%;
        svg {
          fill: var(--white);
          height: 100px;
          width: auto;
        }
      }
    }
    .main-header--bkg {
      .app-img {
        display: none;
      }
      .desktop-img {
        display: block;
      }
    }
  }
  @media ${media.md} {
    height: 81vh;
    .main-header {
      width: 90%;
      margin: 0 auto;
      h1 {
        /* span {
          .dot {
            bottom: 14px;
          }
        } */
      }
    }
  }
  @media ${media.xl} {
    height: 37.5rem;
    padding: 0;
    margin: 1rem auto 0 auto;
    max-width: var(--maxWidth);
    .main-header {
      h1 {
        padding-top: 4rem;
        /* span {
          .dot {
            bottom: 19.5px !important;
          }
        } */
      }
      p {
        font-size: 1.4rem;
      }
    }
  }
`
const ApproachHeader = ({ children, alt }) => (
  <MainHeaderStyles className={alt ? 'h-alt' : ''}>
    <div className="main-header">{children}</div>
    <div className="main-header--bkg">
      <StaticImage
        src="../../images/mob-app.jpg"
        alt=""
        className="app-img"
      />
      <StaticImage
        src="../../images/desktop-app.jpg"
        alt=""
        className="desktop-img"
      />
    </div>
  </MainHeaderStyles>
)

export default ApproachHeader
