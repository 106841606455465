import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const ApproachBodyStyles = styled.section`
  width: 100%;
  padding-bottom: 3rem;
  @media ${media.md} {
    padding-bottom: 1rem;
  }
  .a-b {
    width: 100%;
    margin: var(--auto);
    max-width: var(--maxWidth);
    &--wrapper {
      &--text {
        padding-top: 1rem;
        width: 85%;
        margin: var(--auto);

        h2,
        h3 {
          margin-top: 0.5rem;
          font-size: 2.5rem;
          position: relative;
          font-weight: 900;
          letter-spacing: 1px;
          .dot {
            position: absolute;
            font-size: 3rem;
            color: var(--cyan);
            bottom: 3px;
          }
        }
        .subtitle {
          color: var(--cyan);
          text-transform: uppercase;
          font-family: var(--PoppinsMedium);
          font-size: 22px;
          font-weight: 800;
          letter-spacing: 3px;
        }
        p {
          margin-top: 1.5rem;
        }
      }
      &--img {
        margin-top: 1.5rem;
        .gatsby-image-wrapper {
          height: 20rem;
        }
      }
    }
    @media ${media.sm} {
      &--wrapper {
        h2,
        h3 {
          margin-top: 1rem;
          font-size: 2.85rem;
          position: relative;
          font-weight: 900;
          letter-spacing: 1px;
          .dot {
            position: absolute;
            font-size: 3rem;
            color: var(--cyan);
            bottom: 3px;
          }
        }
        &--img {
          .gatsby-image-wrapper {
            height: 30rem;
          }
        }
      }
    }
    @media ${media.md} {
      padding: 1rem 0;
      .large {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .a-b--wrapper--img {
          width: 100%;
          height: 100%;
          margin: 0;
          .gatsby-image-wrapper {
            height: 53rem;
          }
        }
        .a-b--wrapper--text {
          display: flex;
          flex-direction: column;
          /* align-items: center; */
          justify-content: center;
        }
      }
      .regular {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'a b';
        .a-b--wrapper--img {
          width: 100%;
          height: 100%;
          margin: 0;
          grid-area: b;
          .gatsby-image-wrapper {
            height: 40rem;
          }
        }
        .a-b--wrapper--text {
          display: flex;
          flex-direction: column;
          /* align-items: center; */
          justify-content: center;
        }
      }
      .regular-two {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'a b';
        .a-b--wrapper--img {
          width: 100%;
          height: 100%;
          margin: 0;
          grid-area: a;
          .gatsby-image-wrapper {
            height: 40rem;
          }
        }
        .a-b--wrapper--text {
          display: flex;
          flex-direction: column;
          /* align-items: center; */
          justify-content: center;
        }
      }
    }
    @media ${media.xl} {
      .large {
        .a-b--wrapper--img {
          width: 100%;
          height: 100%;
          margin: 0;
          .gatsby-image-wrapper {
            height: 49rem;
          }
        }
        .a-b--wrapper--text {
          width: 70%;
          margin: 0 auto 0 2rem;
        }
      }
      .regular {
        .a-b--wrapper--img {
          .gatsby-image-wrapper {
            height: 38rem;
          }
        }
        .a-b--wrapper--text {
          width: 60%;
          margin: 0 2.5rem 0 auto;
        }
      }
      .regular-two {
        .a-b--wrapper--img {
          .gatsby-image-wrapper {
            height: 35rem;
          }
        }
        .a-b--wrapper--text {
          width: 60%;
          margin: 0 auto 0 2rem;
        }
      }
    }
  }
`
const ApproachBody = () => (
  <ApproachBodyStyles>
    <div className="a-b">
      <section className="a-b--wrapper large">
        <div className="a-b--wrapper--img m-nd">
          <StaticImage src="../../images/bttf.png" alt="Placeholder" />
        </div>
        <div className="a-b--wrapper--text">
          <p className="subtitle bold">Who we are</p>
          <h2>
            <span>
              We're <br /> The Experts<span className="dot">.</span>
            </span>
          </h2>
          <p>
            At Dewynters we believe in campaigns that are creative and
            ambitious, but always driven by cultural insights and data-driven
            strategies. In an attention economy where just a few seconds of
            someone’s time are precious, that can make all the difference.
          </p>
          <p>
            Competition has changed. The hottest streaming show, a breaking news
            story, that latest TikTok challenge – they’re all fighting for
            attention.
          </p>
          <p>
            Our campaigns demand attention. Using emotional storytelling, sharp
            thinking and in-depth performance analysis, we cut through all the
            other noise and connect audiences to those unforgettable shared
            moments that only live entertainment can deliver.
          </p>
        </div>
      </section>
      <section className="a-b--wrapper regular">
        <div className="a-b--wrapper--img">
          <StaticImage src="../../images/why.png" alt="Placeholder" />
        </div>
        <div className="a-b--wrapper--text">
          <p className="subtitle bold">What we do</p>
          <h2>
            <span>
              We Do <br /> Everything<span className="dot">.</span>
            </span>
          </h2>
          <p>
            As an integrated agency we do it all — brand strategy, creative,
            content, social, ticketing and sales analysis, insights, media
            buying and more. Our specialist teams work together to ensure your
            campaign is always performing where and when it matters.
          </p>
        </div>
      </section>
      <section className="a-b--wrapper regular-two">
        <div className="a-b--wrapper--img">
          <StaticImage
            src="../../images/winterwonderland.png"
            alt="Placeholder"
          />
        </div>
        <div className="a-b--wrapper--text">
          <p className="subtitle bold">Why we do it </p>
          <h2>
            <span>
              We're <br /> Passionate<span className="dot">.</span>
            </span>
          </h2>
          <p>
            The unique thrill of live entertainment is our passion. It’s what we
            live for. Which is why no one understands better how audiences feel,
            think or behave. Because when the lights go down and the excitement
            ripples through the crowd, you’ll find us right there with them.
          </p>
        </div>
      </section>
    </div>
  </ApproachBodyStyles>
)

export default ApproachBody
